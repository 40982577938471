export const CLINIC_SPECIALIZATION_OPTIONS = [
  { value: 'pediatrics', label: 'Pediatrics' },
  { value: 'general', label: 'General' },
  { value: 'cardiology', label: 'Cardiology' },
  { value: 'neurology', label: 'Neurology' },
  { value: 'dermatology', label: 'Dermatology' },
  { value: 'oncology', label: 'Oncology' },
  { value: 'gynecology', label: 'Gynecology' },
  { value: 'urology', label: 'Urology' },
  { value: 'pulmonology', label: 'Pulmonology' }
]
