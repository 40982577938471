import { defineMessages } from 'react-intl'

export default defineMessages({
  latestPatients: {
    id: 'dashboard.latestPatients.title',
    defaultMessage: 'Latest Patients'
  },
  noPatients: {
    id: 'app.components.LatestPatients.noPatients',
    defaultMessage: 'No patients found'
  }
})
