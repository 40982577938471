import { InfoIcon } from '@chakra-ui/icons'
import { Flex, Textarea, Text, Tooltip } from '@chakra-ui/react'
import { Field } from 'react-final-form'

interface Props {
  name: string
  label: string
  description?: string
}

export default function TextareaField({ name, label, description }: Props) {
  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <Flex flexDir="column" gap={1}>
          <Flex alignItems="center" gap={1}>
            <Text>{label}</Text>
            {description && (
              <Tooltip label={description}>
                <InfoIcon boxSize={3} />
              </Tooltip>
            )}
          </Flex>

          <Textarea size="xs" {...input} />
          {meta.touched && meta.error && (
            <span className="text-xs text-red-500">{meta.error}</span>
          )}
        </Flex>
      )}
    />
  )
}
