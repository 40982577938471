import { Button, RepeatClockIcon } from '@chakra-ui/icons'
import Banner from '../../../common/components/Banner'
import { FormattedMessage, useIntl } from 'react-intl'
import messages from './messages'
import { useContactTrigger } from '../../../common/hooks/useContactTrigger'

interface Props {
  remainingVisits: number
}

export default function TrialEndingBanner({ remainingVisits }: Props) {
  const { formatMessage } = useIntl()
  const { triggerContact } = useContactTrigger()

  return (
    <Banner
      media={
        <RepeatClockIcon boxSize={8} ml={4} alignSelf="center" color="red" />
      }
      title={formatMessage(messages.trialEnding)}
      description={formatMessage(messages.trialEndingDescription, {
        remaining: remainingVisits
      })}
      action={
        <Button colorScheme="blue" size="md" onClick={triggerContact}>
          <FormattedMessage {...messages.contactUs} />
        </Button>
      }
    />
  )
}
