import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type RootState } from '../store'
import { replaceElementById } from '../utils/dataUtils'

interface VisitsState {
  value: any[]
}

const initialState: VisitsState = {
  value: []
}

export const visitsSlice = createSlice({
  name: 'visits',
  initialState,
  reducers: {
    setVisits: (state, action: PayloadAction<any[]>) => {
      state.value = action.payload
    },
    reverseVisits: (state) => {
      state.value = state.value.reverse()
    },
    deleteVisit: (state, action: PayloadAction<string>) => {
      state.value = state.value.filter((visit) => visit.id !== action.payload)
    },
    addVisit: (
      state,
      { payload }: PayloadAction<{ visit: any; sortOrder: 'ASC' | 'DESC' }>
    ) => {
      state.value =
        payload.sortOrder === 'ASC'
          ? [...state.value, payload.visit]
          : [payload.visit, ...state.value]
    },
    updateVisit: (state, action: PayloadAction<any>) => {
      state.value = replaceElementById(
        state.value,
        action.payload.id,
        action.payload
      )
    }
  }
})

export const { setVisits, reverseVisits, deleteVisit, addVisit, updateVisit } =
  visitsSlice.actions

export const selectCurrentVisits = (state: RootState) => state.visits.value
export const getLatestVisitWithWeight = (state: RootState) => {
  const visits = state.visits.value
  if (!Array.isArray(visits) || visits.length === 0) {
    return null
  }

  return visits
    .filter((visit) => !!visit.weight)
    .reduce((maxDateObject, currentObject) => {
      const currentDate = new Date(currentObject.date)
      const maxDate = maxDateObject ? new Date(maxDateObject.date) : null

      if (!maxDate || currentDate > maxDate) {
        return currentObject
      } else {
        return maxDateObject
      }
    }, null)
}

export default visitsSlice.reducer
