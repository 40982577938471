/* eslint-disable @typescript-eslint/indent */
/* eslint-disable multiline-ternary */
import { FormattedMessage, useIntl } from 'react-intl'
import BasePageLayout from '../../../common/components/BasePageLayout'
import Statistics from '../../components/Statistics'
import messages from './messages'
import Header from '../../../common/components/Header'
import {
  Flex,
  IconButton,
  Img,
  Button,
  useDisclosure,
  Grid
} from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/24/outline'
import useLatestPatients from '../../hooks/useLatestPatients'
import useStatistics from '../../hooks/useStatistics'
import Finances from '../../components/Finances'
import LatestPatients from '../../components/LatestPatients'
import Banner from '../../../common/components/Banner'
import PatientModal from '../../../patient/components/PatientModal'
import { useAccount } from '../../../account/AccountProvider'
import useDoctorProfile from '../../../common/hooks/useDoctorProfile'
import {
  differenceBetweenTodayAndDate,
  isLessThanFourteenDaysLeft
} from '../../../../../utils/dateUtils'
import PlanExpiringBanner from '../../components/PlanExpiringBanner'
import TrialEndingBanner from '../../components/TrialEndingBanner'
import { useContactTrigger } from '../../../common/hooks/useContactTrigger'

function Dashboard() {
  const { formatMessage } = useIntl()

  const {
    schema,
    visitPrice,
    followUpPrice,
    freeMaxUsage,
    isSubscribed,
    planExpiresAt
  } = useAccount()
  const { statistics, loading: doctorProfileLoading } = useDoctorProfile()

  const { latestPatients, loading: latestPatientsLoading } = useLatestPatients()
  const { visitsThisMonth, followUpsThisMonth, totalVisits, totalFollowUps } =
    useStatistics(statistics)

  const remainingVisits = freeMaxUsage - (totalFollowUps + totalVisits)
  const remainingDays = differenceBetweenTodayAndDate(planExpiresAt)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { triggerContact } = useContactTrigger()

  return (
    <BasePageLayout isCentered>
      <Flex justifyContent="space-between">
        <Header>
          <FormattedMessage {...messages.pageTitle} />
        </Header>
        <IconButton
          isRound={true}
          variant="solid"
          colorScheme="blue"
          _hover={{ bg: 'blue.600' }}
          size="lg"
          aria-label="Add patient"
          icon={<PlusIcon width={30} />}
          onClick={onOpen}
        />
      </Flex>

      {!isSubscribed && remainingVisits < 20 && !doctorProfileLoading ? (
        <TrialEndingBanner remainingVisits={remainingVisits} />
      ) : null}
      {isSubscribed &&
      isLessThanFourteenDaysLeft(planExpiresAt) &&
      !doctorProfileLoading ? (
        <PlanExpiringBanner remainingDays={remainingDays} />
      ) : null}

      <Statistics
        statistics={statistics}
        doctorProfileLoading={doctorProfileLoading}
      />
      <Flex flexDir="column" gap={4}>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)'
          }}
          gridGap={4}
        >
          <Finances
            doctorProfileLoading={doctorProfileLoading}
            visitsThisMonth={visitsThisMonth}
            followUpsThisMonth={followUpsThisMonth}
            visitPrice={visitPrice}
            followUpPrice={followUpPrice}
          />
          <LatestPatients
            latestPatients={latestPatients}
            latestPatientsLoading={latestPatientsLoading}
          />
        </Grid>
      </Flex>

      <Banner
        media={<Img src="/sijilo.png" alt="logo" width="10%" />}
        title={formatMessage(messages.welcome)}
        description={formatMessage(messages.hereForYou)}
        action={
          <Button colorScheme="blue" size="md" onClick={triggerContact}>
            <FormattedMessage {...messages.contactUs} />
          </Button>
        }
      />

      <PatientModal
        historySchema={schema.history}
        isOpen={isOpen}
        onClose={onClose}
      />
    </BasePageLayout>
  )
}

export default Dashboard
