import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { LockIcon } from '@chakra-ui/icons'
import { useAccount } from '../../AccountProvider'
import useAccountSubscription from '../../hooks/useAccountSubscription'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useContactTrigger } from '../../../common/hooks/useContactTrigger'

export default function AccountFrozen() {
  const navigate = useNavigate()

  const doctorProfile = useAccount()
  const { isFreeMaxUsageReached, isPlanExpired } =
    useAccountSubscription(doctorProfile)

  const { triggerContact } = useContactTrigger()

  useEffect(() => {
    if (!isFreeMaxUsageReached && !isPlanExpired && !doctorProfile.loading) {
      navigate('/')
    }
  }, [doctorProfile.loading, isFreeMaxUsageReached, isPlanExpired, navigate])

  return (
    <Flex className="w-screen h-screen flex justify-center items-center">
      <Flex justifyContent="center" flexDir="column" gap={4}>
        <LockIcon color="red.500" boxSize={8} alignSelf="center" />
        <Box>
          <Text fontSize="2xl" fontWeight="bold" textAlign="center">
            <FormattedMessage {...messages.accountFrozen} />
          </Text>
          <Text color="gray.500" textAlign="center">
            <FormattedMessage {...messages.accountFrozenDescription} />
          </Text>
          <Text color="gray.500" textAlign="center">
            <FormattedMessage {...messages.accountFrozenDescription2} />
          </Text>
        </Box>
        <Button
          colorScheme="blue"
          size="lg"
          alignSelf="center"
          onClick={triggerContact}
        >
          <FormattedMessage {...messages.contactUs} />
        </Button>
      </Flex>
    </Flex>
  )
}
