import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type UserMemoryLists } from '../model/MemoryList'
import { type RootState } from '../store'

interface MemoryListsState {
  value: UserMemoryLists
  stagedUpdates: UserMemoryLists
}

const initialState: MemoryListsState = {
  value: {},
  stagedUpdates: {}
}

export const memoryListsSlice = createSlice({
  name: 'memoryListsSlice',
  initialState,
  reducers: {
    setMemoryLists: (
      state,
      action: PayloadAction<UserMemoryLists | undefined>
    ) => {
      if (!action.payload) return
      state.value = action.payload
    },
    stageMemoryListUpdates: (state, action: PayloadAction<any>) => {
      state.stagedUpdates[action.payload.name] = [
        ...new Set([
          ...action.payload.values, // new values
          ...(state.stagedUpdates[action.payload.name]
            ? state.stagedUpdates[action.payload.name]
            : []) // locally staged values
        ])
      ]
    },
    resetStagedUpdates: (state) => {
      state.stagedUpdates = {}
    },
    deleteMemoryListItem: (state, action: PayloadAction<any>) => {
      state.value[action.payload.name] = state.value[
        action.payload.name
      ].filter((value: any) => value !== action.payload.valueToDelete)
    },
    pushStagedUpdates: (state) => {
      for (const key of Object.keys(state.stagedUpdates)) {
        state.value[key] = [
          ...new Set([
            ...(state.value[key] ? state.value[key] : []), // existing values
            ...(state.stagedUpdates[key] ? state.stagedUpdates[key] : []) // locally staged values
          ])
        ]
      }
    }
  }
})

export const {
  setMemoryLists,
  stageMemoryListUpdates,
  resetStagedUpdates,
  pushStagedUpdates,
  deleteMemoryListItem
} = memoryListsSlice.actions

export const selectMemoryLists = (state: RootState) => state.memoryLists.value
export const selectStagedUpdates = (state: RootState) =>
  state.memoryLists.stagedUpdates

export default memoryListsSlice.reducer
