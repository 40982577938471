import { Button, RepeatClockIcon } from '@chakra-ui/icons'
import Banner from '../../../common/components/Banner'
import { FormattedMessage, useIntl } from 'react-intl'
import messages from './messages'
import { useContactTrigger } from '../../../common/hooks/useContactTrigger'

interface Props {
  remainingDays: number
}

export default function PlanExpiringBanner({ remainingDays }: Props) {
  const { formatMessage } = useIntl()
  const { triggerContact } = useContactTrigger()

  return (
    <Banner
      media={
        <RepeatClockIcon boxSize={8} ml={4} alignSelf="center" color="orange" />
      }
      title={formatMessage(messages.planExpiring)}
      description={formatMessage(messages.planExpiringDescription, {
        remaining: remainingDays
      })}
      action={
        <Button colorScheme="blue" size="md" onClick={triggerContact}>
          <FormattedMessage {...messages.contactUs} />
        </Button>
      }
    />
  )
}
