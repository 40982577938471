export function replaceElementById<T extends { id: string }>(
  arr: T[],
  id: string,
  newValue: T
): T[] {
  const indexToReplace = arr.findIndex((item) => item.id === id)

  if (indexToReplace === -1) {
    // If id is not found in the array, return the original array
    return arr
  }

  return arr.flatMap((value, i) =>
    i === indexToReplace ? [newValue] : [value]
  )
}
