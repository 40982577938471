/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { InfoIcon } from '@chakra-ui/icons'
import { toCamelCase } from '../../../../utils/schemaUtils'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Tag,
  Tooltip
} from '@chakra-ui/react'

export function generateVisitFields(
  field: any,
  values: any,
  subCategoryLevel = 0
) {
  if (values.isFollowUp) {
    if (field.type === 'subcategory' && !field.isFollowUp) {
      return field.fields?.map((subField: any) =>
        generateVisitFields(subField, values, subCategoryLevel + 1)
      )
    } else {
      if (values.isFollowUp && !field.isFollowUp) return null
      return (
        <div key={field.id} className="mt-4">
          <Flex alignItems="center" gap={1}>
            <h3 className="font-bold">{field.displayName}</h3>
            {field.description && (
              <Tooltip label={field.description}>
                <InfoIcon boxSize={3} />
              </Tooltip>
            )}
          </Flex>
          <p>
            {values[toCamelCase(field.displayName)]
              ? values[toCamelCase(field.displayName)]
              : '-'}
          </p>
        </div>
      )
    }
  }

  if (field.type === 'subcategory') {
    return (
      <Accordion allowMultiple allowToggle className="col-span-2">
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                {field.displayName}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} className="grid grid-cols-2">
            {field.fields?.map((subField: any) =>
              generateVisitFields(subField, values, subCategoryLevel + 1)
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    )
  }
  if (field.type === 'memoryList') {
    return (
      <div key={field.id} className="mt-4">
        <h3 className="font-bold">{field.displayName}</h3>
        {values[toCamelCase(field.displayName)]?.map((value: string) => (
          <Tag className="mr-2" key={value}>
            {value}
          </Tag>
        ))}
      </div>
    )
  } else {
    return (
      <div key={field.id} className="mt-4">
        <Flex alignItems="center" gap={1}>
          <h3 className="font-bold">{field.displayName}</h3>
          {field.description && (
            <Tooltip label={field.description}>
              <InfoIcon boxSize={3} />
            </Tooltip>
          )}
        </Flex>
        <p>
          {values[toCamelCase(field.displayName)]
            ? `${values[toCamelCase(field.displayName)]} ${field.unit ?? ''}`
            : '-'}
        </p>
      </div>
    )
  }
}
