import { useState } from 'react'
import { Flex, Text, useToast } from '@chakra-ui/react'
import { DeleteIcon, CheckIcon } from '@chakra-ui/icons'
import { deleteMemoryListItem } from '../../../../../reducers/memoryListsReducer'
import { getMemoryListService } from '../../../../../services/Services'
import { useAuthState } from '../../../../../auth/AuthProvider'
import { useDispatch } from 'react-redux'
import { components } from 'react-select'

export default function CustomMemoryListOption(props: any) {
  const [isDeleteClicked, setIsDeleteClicked] = useState(false)
  const { uid } = useAuthState()
  const toast = useToast()
  const dispatch = useDispatch()

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsDeleteClicked(true)
  }

  const handleDeleteConfirm = (e: React.MouseEvent) => {
    e.stopPropagation()
    getMemoryListService().deleteMemoryListItem({
      ownerId: uid,
      memoryListId: props.selectProps.name,
      itemId: props.data.value
    })
    dispatch(
      deleteMemoryListItem({
        name: props.selectProps.name,
        valueToDelete: props.data.value
      })
    )
    setIsDeleteClicked(false)
    toast({
      title: 'Item deleted',
      description: 'The item has been permanently deleted from this list',
      status: 'success'
    })
  }

  return (
    <components.Option {...props}>
      <Flex justify="space-between" align="center" width="100%">
        <div>{props.data.label}</div>
        {!isDeleteClicked && (
          <DeleteIcon
            cursor="pointer"
            onClick={handleDeleteClick}
            color="gray.500"
            _hover={{ color: 'red.500' }}
            h={4}
            w={4}
          />
        )}
        {isDeleteClicked && (
          <Flex gap={2}>
            <Text fontSize="xs" color="gray.500">
              Are you sure?
            </Text>
            <CheckIcon
              cursor="pointer"
              onClick={handleDeleteConfirm}
              color="red.500"
              h={4}
              w={4}
            />
          </Flex>
        )}
      </Flex>
    </components.Option>
  )
}
