import {
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { Field, useField } from 'react-final-form'
import { formatDateToYYYYMMDD } from '../../../../utils/dateUtils'
import { InfoIcon } from '@chakra-ui/icons'

interface Props {
  name: string
  label: string
  type?: string
  validate?: any
  unit?: string
  description?: string
}

export default function InputField({
  name,
  label,
  type = 'text',
  validate,
  unit,
  description
}: Props) {
  const {
    input: { value: initialValue }
  } = useField(name)

  return (
    <Field
      validate={validate}
      initialValue={
        type === 'date' && !initialValue
          ? formatDateToYYYYMMDD(new Date())
          : undefined
      }
      name={name}
      render={({ input, meta }) => (
        <Flex flexDir="column" gap={1}>
          <Flex alignItems="center" gap={1}>
            <Text>
              {label} {validate && '*'}
            </Text>
            {description && (
              <Tooltip label={description}>
                <InfoIcon boxSize={3} />
              </Tooltip>
            )}
          </Flex>
          <InputGroup size="sm">
            <Input size="sm" type={type} {...input} />
            {unit ? <InputRightAddon>{unit}</InputRightAddon> : null}
          </InputGroup>
          {meta.touched && meta.error && (
            <span className="text-xs text-red-500">{meta.error}</span>
          )}
        </Flex>
      )}
    />
  )
}
