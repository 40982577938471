import { Card, Flex, Skeleton, Text } from '@chakra-ui/react'
import { SparklesIcon } from '@heroicons/react/24/solid'
import Markdown from 'react-markdown'

export default function AISummary({
  summary,
  summaryLoading
}: {
  summary: string
  summaryLoading: boolean
}) {
  if (!summary && !summaryLoading) return null
  return (
    <Flex justifyContent="center">
      <Card p={6} m={4} bg="gray.50" w="100%" shadow="md">
        <Flex gap={2} mb={2}>
          <SparklesIcon color="gold" width="20px" />
          <Text fontSize="lg" fontWeight="bold">
            AI Summary
          </Text>
        </Flex>
        {summaryLoading && (
          <Flex flexDir="column" gap={2}>
            <Skeleton height="20px" width="40%" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Flex>
        )}
        {summary && summary.length > 0 && !summaryLoading && (
          <Markdown skipHtml>{summary}</Markdown>
        )}
      </Card>
    </Flex>
  )
}
