/* eslint-disable @typescript-eslint/indent */
import type { DoctorsService } from './DoctorsService'
import { type FirestoreService } from '../http/FirestoreService'
import { type DoctorType } from '../../model/doctor'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../db'

export class DoctorsServiceImpl implements DoctorsService {
  private readonly firestoreService: FirestoreService

  private static readonly collectionName = 'doctor'

  constructor(firestoreService: FirestoreService) {
    this.firestoreService = firestoreService
  }

  async getDoctorProfile({
    ownerId
  }: {
    ownerId: string
  }): Promise<DoctorType | undefined> {
    try {
      const doctorProfile = await this.firestoreService.getDocument<DoctorType>(
        DoctorsServiceImpl.collectionName,
        ownerId
      )

      // resolve schemaId to schema
      if (doctorProfile?.schemaId) {
        const schemaDoc = await getDoc(doctorProfile.schemaId)

        if (!schemaDoc.exists()) {
          throw new Error('Schema not found')
        }

        return {
          ...doctorProfile,
          schema: schemaDoc.data() as DoctorType['schema']
        }
      }

      return doctorProfile
    } catch (error) {
      console.error('Error getting doctor profile', error)
    }
  }

  async onboardDoctor({
    ownerId,
    doctorData
  }: {
    ownerId: string
    doctorData: Partial<DoctorType> & { schemaId?: string }
  }): Promise<void> {
    const doctorProfile = await this.getDoctorProfile({ ownerId })
    if (doctorProfile?.isOnboarded) {
      return
    }

    const { schemaId, ...restDoctorData } = doctorData

    await this.firestoreService.updateDocument(
      DoctorsServiceImpl.collectionName,
      ownerId,
      {
        ...restDoctorData,
        schemaId: schemaId ? doc(db, `schemas/${schemaId}`) : null,
        isOnboarded: true,
        isSubscribed: false,
        requestedContact: false,
        freeMaxUsage: 50,
        statistics: {
          patients: {
            total: 0,
            todayCounter: 0,
            todayDate: 0
          },
          visits: {
            total: 0,
            todayCounter: 0,
            todayDate: 0
          },
          followUps: {
            total: 0,
            todayCounter: 0,
            todayDate: 0
          }
        }
      }
    )
  }

  async updateDoctorProfile({
    ownerId,
    doctorData
  }: {
    ownerId: string
    doctorData: Partial<DoctorType>
  }): Promise<void> {
    await this.firestoreService.updateDocument(
      DoctorsServiceImpl.collectionName,
      ownerId,
      doctorData
    )
  }

  async contactDoctor({ ownerId }: { ownerId: string }): Promise<void> {
    await this.firestoreService.updateDocument(
      DoctorsServiceImpl.collectionName,
      ownerId,
      { requestedContact: true }
    )
  }
}
