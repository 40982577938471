import { useCallback, useEffect, useState } from 'react'
import {
  selectCurrentPatient,
  setCurrentPatient
} from '../../reducers/currentPatientReducer'
import { getPatientService, getVisitService } from '../../services/Services'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

import ActionBar from '../../client/modules/common/components/ActionBar'
import { getItemsWithSummaryReview } from '../../utils/schemaUtils'
import VisitView from './components/VisitView'
import {
  deleteVisit,
  reverseVisits,
  selectCurrentVisits,
  setVisits
} from '../../reducers/currentVisitsReducer'
import EmptyBanner from '../EmptyBanner'
import Header from '../../client/modules/common/components/Header'
import DisplayModeToggle from './components/DisplayModeToggle'
import { type SortOrderType, type DisplayModeType } from './types'
import TimelineItem from './components/VisitDisplays/TimelineItem'
import RowItem from './components/VisitDisplays/RowItem'
import SortOrderToggle from './components/SortOrderToggle'
import { ChartBarIcon } from '@heroicons/react/24/outline'
import { SparklesIcon } from '@heroicons/react/24/solid'
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  useDisclosure,
  Flex,
  Spinner,
  Button
} from '@chakra-ui/react'
import GrowthChart from './components/GrowthChart'
import { SHOULD_SHOW_GROWTH_CHART } from './constants'
import { useParams } from 'react-router-dom'
import { useAccount } from '../../client/modules/account/AccountProvider'
import PatientModal from '../../client/modules/patient/components/PatientModal'
import VisitFormModal from '../../client/modules/patient/components/VisitFormModal'
import AISummary from './components/AISummary'

export default function PatientDossier() {
  const dispatch = useAppDispatch()
  const { schema } = useAccount()

  const {
    isOpen: isPatientModalOpen,
    onOpen: onPatientModalOpen,
    onClose: onPatientModalClose
  } = useDisclosure()

  const {
    isOpen: isVisitViewModalOpen,
    onOpen: onVisitViewModalOpen,
    onClose: onVisitViewModalClose
  } = useDisclosure()

  const {
    isOpen: isVisitFormModalOpen,
    onOpen: onVisitFormModalOpen,
    onClose: onVisitFormModalClose
  } = useDisclosure()

  const [visitsLoading, setVisitsLoading] = useState(false)

  const { patientId } = useParams()

  const visitSchema = getItemsWithSummaryReview(schema.visit)

  const currentPatient = useAppSelector(selectCurrentPatient)
  const currentVisits = useAppSelector(selectCurrentVisits)

  const [followUpFlag, setFollowUpFlag] = useState(false)
  const [displayMode, setDisplayMode] = useState<DisplayModeType>('TIMELINE')
  const [sortOrder, setSortOrder] = useState<SortOrderType>('ASC')
  const [summaryLoading, setSummaryLoading] = useState(false)
  const [summary, setSummary] = useState<string>('')

  const [showCharts, setShowCharts] = useState(false)

  const [currentVisit, setCurrentVisit] = useState<any>(null)

  const fetchOnePatient = useCallback(async () => {
    if (patientId && currentPatient?.id !== patientId) {
      const patient = await getPatientService().getOnePatient({ patientId })
      if (patient) {
        dispatch(setCurrentPatient(patient))
      }
    }
  }, [patientId, dispatch, currentPatient.id])

  const fetchVisits = useCallback(async () => {
    if (patientId) {
      setSummary('')
      setVisitsLoading(true)
      const _visits = await getVisitService().getVisits(patientId)
      dispatch(setVisits(_visits))
      setVisitsLoading(false)
    }
  }, [patientId, dispatch])

  const handleVisitDelete = (visitId: string) => {
    if (!patientId) {
      return
    }

    getVisitService()
      .deleteVisit(patientId, visitId)
      .then(() => {
        dispatch(deleteVisit(visitId))
      })
  }

  const handleVisitEdit = async (visitId: string) => {
    const filteredVisit = currentVisits.find((visit) => visit.id === visitId)
    setCurrentVisit(filteredVisit)
    setFollowUpFlag(filteredVisit.isFollowUp)
    onVisitFormModalOpen()
  }

  const handleVisitSelect = (visitId: string) => {
    setCurrentVisit(currentVisits.find((visit) => visit.id === visitId))
    onVisitViewModalOpen()
  }

  const toggleDisplayMode = () => {
    setDisplayMode(displayMode === 'TIMELINE' ? 'TABLE' : 'TIMELINE')
  }

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC')
    dispatch(reverseVisits())
  }

  // if there is no patient, this call won't be made
  useEffect(() => {
    if (currentPatient) {
      fetchVisits()
    }
  }, [currentPatient, fetchVisits])

  // if there is no patient, this call will be made, and visits will be fetched from inside
  useEffect(() => {
    if (patientId && currentPatient.id !== patientId) {
      fetchOnePatient()
    }
  }, [currentPatient.id, fetchOnePatient, patientId])

  const handleGenerateSummary = async () => {
    setSummaryLoading(true)
    const summaryText = await getVisitService().getAISummary(
      currentPatient,
      currentVisits
    )
    setSummary(summaryText)
    setSummaryLoading(false)
  }

  return (
    <div className="flex flex-col sm:fixed text-left p-4 sm:w-9/12 w-full overflow-y-scroll top-16 bottom-0">
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        px={4}
        gap={4}
        my={4}
      >
        <Header>Visits & Follow Ups</Header>
        <Flex gap={4} wrap="wrap">
          <DisplayModeToggle
            displayMode={displayMode}
            onToggle={toggleDisplayMode}
          />
          <SortOrderToggle sortOrder={sortOrder} onToggle={toggleSortOrder} />
          {currentVisits.length > 0 && (
            <Button
              size="sm"
              backgroundColor="purple.600"
              color="white"
              _hover={{
                backgroundColor: 'purple.700'
              }}
              onClick={handleGenerateSummary}
              leftIcon={<SparklesIcon color="gold" width="20px" />}
            >
              Generate AI Summary
            </Button>
          )}
          {SHOULD_SHOW_GROWTH_CHART && (
            <Button
              isActive={showCharts}
              size="sm"
              onClick={() => {
                setShowCharts(!showCharts)
              }}
            >
              <ChartBarIcon className="h-5 w-5 text-blue-500" /> Growth Charts
            </Button>
          )}
        </Flex>
      </Flex>

      {showCharts ? <GrowthChart /> : null}

      {currentVisits.length === 0 && !visitsLoading && (
        <EmptyBanner isPatientSelected={true} />
      )}

      {visitsLoading && (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      )}

      <PatientModal
        historySchema={schema.history}
        isOpen={isPatientModalOpen}
        onClose={onPatientModalClose}
      />

      <VisitFormModal
        key={currentPatient.id}
        patientName={currentPatient.name}
        currentVisitValues={currentVisit}
        isEditMode={!!currentVisit}
        isFollowUp={followUpFlag}
        sortOrder={sortOrder}
        isOpen={isVisitFormModalOpen}
        onClose={onVisitFormModalClose}
      />

      <VisitView
        values={currentVisit}
        isOpen={isVisitViewModalOpen}
        onClose={onVisitViewModalClose}
      />

      <AISummary summary={summary} summaryLoading={summaryLoading} />

      {displayMode === 'TABLE' && currentVisits.length > 0 && (
        <TableContainer className="overflow-x-auto px-8 py-4">
          <Table size="sm">
            <Thead>
              <Tr>
                {['', 'Date', ...visitSchema, 'Actions'].map((item) => (
                  <Th key={item}>{item}</Th>
                ))}
              </Tr>
            </Thead>

            <Tbody>
              {currentVisits?.map((visit) => (
                <RowItem
                  key={visit.id}
                  visit={visit}
                  birthDate={currentPatient.dateOfBirth}
                  onVisitSelect={handleVisitSelect}
                  onVisitEdit={handleVisitEdit}
                  onVisitDelete={handleVisitDelete}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {displayMode === 'TIMELINE' && (
        <div className="flex flex-col px-8 py-4">
          {currentVisits?.map((visit) => (
            <TimelineItem
              key={visit.id}
              visit={visit}
              birthDate={currentPatient.dateOfBirth}
              onVisitSelect={handleVisitSelect}
              onVisitEdit={handleVisitEdit}
              onVisitDelete={handleVisitDelete}
            />
          ))}
        </div>
      )}
      <div className="fixed bottom-0 my-12 z-10 mx-20">
        <ActionBar
          onAddPatientClick={onPatientModalOpen}
          onAddVisitClick={() => {
            setFollowUpFlag(false)
            setCurrentVisit(null)
            onVisitFormModalOpen()
          }}
          onAddFollowUpClick={() => {
            setCurrentVisit(null)
            onVisitFormModalOpen()
            setFollowUpFlag(true)
          }}
          isPatientSelected={currentPatient.id !== ''}
        />
      </div>
    </div>
  )
}
