/* eslint-disable @typescript-eslint/indent */
import moment from 'moment'

type MonthNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export function formatDateToYYYYMMDD(date: Date): string {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const makeDate = (timestamp: number) => {
  const options: any = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
    // You can also specify other options like timeZoneName, hour12, etc. as needed.
  }

  return new Date(timestamp).toLocaleString(undefined, options)
}

export const makeAlphaDate = (timestamp: number) => {
  const options: any = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
    // You can also specify other options like timeZoneName, hour12, etc. as needed.
  }

  return new Date(timestamp).toLocaleString(undefined, options)
}

export function getAge(birthDate: string): string {
  if (!birthDate) return ''

  const birthDateParts = birthDate.split('.')

  if (birthDateParts.length !== 3) {
    throw new Error('Invalid birthdate format. Please use DD.MM.YYYY.')
  }

  const birthDay = parseInt(birthDateParts[0])
  const birthMonth = parseInt(birthDateParts[1])
  const birthYear = parseInt(birthDateParts[2])

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1 // Months are zero-based
  const currentDay = currentDate.getDate()

  let years = currentYear - birthYear
  let months = currentMonth - birthMonth
  let days = currentDay - birthDay

  // Check if there is a negative difference in months or days
  if (days < 0) {
    months--
    const lastMonthDate = new Date(currentYear, currentMonth - 1, 0)
    days += lastMonthDate.getDate()
  }
  if (months < 0) {
    years--
    months += 12
  }

  return `${years}y, ${months}m, ${days}d`
}

export function getAgeAtDate(birthMoment: string, dateMoment: number): string {
  const cleanedBirthMoment = moment(birthMoment, 'DD.MM.YYYY')
  const cleanedDateMoment = moment(makeDate(dateMoment), 'DD.MM.YYYY')

  const years = cleanedDateMoment.diff(cleanedBirthMoment, 'years')
  cleanedBirthMoment.add(years, 'years')

  const months = cleanedDateMoment.diff(cleanedBirthMoment, 'months')
  cleanedBirthMoment.add(months, 'months')

  const days = cleanedDateMoment.diff(cleanedBirthMoment, 'days')

  return `${years}y, ${months}m, ${days}d`
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export function getCurrentYear(): `20${number}` {
  const date = new Date()
  return date.getFullYear() as unknown as `20${number}`
}

export function getCurrentMonthNumber() {
  const date = new Date()
  return (date.getMonth() + 1) as MonthNumber
}

export function getLastMonthNumber() {
  const currentMonth = getCurrentMonthNumber()
  return currentMonth === 1 ? 12 : ((currentMonth - 1) as MonthNumber)
}

export function getCurrentMonthName() {
  const date = new Date()
  return monthNames[date.getMonth()]
}

// this function returns true if the date input is older than midnight of the current day (beginning of the day)
export function isOutdatedDate(todayDate: number): boolean {
  const inputDate = new Date(todayDate)
  const currentDate = new Date()

  // Set the time to midnight for both dates to compare only the date part
  inputDate.setHours(0, 0, 0, 0)
  currentDate.setHours(0, 0, 0, 0)

  return inputDate < currentDate
}

export function differenceBetweenTodayAndDate(timestamp: number): number {
  const inputDate = new Date(timestamp)
  const currentDate = new Date()

  // Calculate the difference in time
  const timeDifference = inputDate.getTime() - currentDate.getTime()

  // Convert time difference from milliseconds to days
  return Math.ceil(timeDifference / (1000 * 3600 * 24))
}

export function isLessThanFourteenDaysLeft(timestamp: number): boolean {
  return differenceBetweenTodayAndDate(timestamp) <= 14
}
