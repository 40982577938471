/* eslint-disable multiline-ternary */
import { Avatar, Box, Flex, Spinner, Text } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import Header from '../../../common/components/Header'
import { makeAlphaDate } from '../../../../../utils/dateUtils'
import messages from './messages'
import { type Patient } from '../../../../../model/patient'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '../../../../../store/hooks'
import { setCurrentPatient } from '../../../../../reducers/currentPatientReducer'
import { setVisits } from '../../../../../reducers/currentVisitsReducer'

interface Props {
  latestPatients: Patient[]
  latestPatientsLoading: boolean
}

export default function LatestPatients({
  latestPatients,
  latestPatientsLoading
}: Props) {
  const dispatch = useAppDispatch()

  const handlePatientClick = (patient: Patient) => {
    dispatch(setVisits([]))
    dispatch(setCurrentPatient(patient))
  }

  return (
    <Flex flex={1} flexDir="column" gap={4}>
      <Header>
        <FormattedMessage {...messages.latestPatients} />
      </Header>
      <Box flex={1} p={4} borderStyle="solid" borderWidth={1} borderRadius={10}>
        {latestPatientsLoading ? (
          <Flex justifyContent="center">
            <Spinner />
          </Flex>
        ) : latestPatients.length === 0 ? (
          <Flex justifyContent="center" alignItems="center" height="100%">
            <Text color="gray.500">
              <FormattedMessage {...messages.noPatients} />
            </Text>
          </Flex>
        ) : (
          <Flex flexDir="column" gap={1}>
            {latestPatients.map((patient) => (
              <Link to={`/patients/${patient.id}`} key={patient.id}>
                <Flex
                  p={1}
                  borderRadius={10}
                  _hover={{ bg: 'gray.50' }}
                  justifyContent="space-between"
                  onClick={() => {
                    handlePatientClick(patient)
                  }}
                >
                  <Flex gap={4}>
                    <Avatar size="sm" name={patient.name} />
                    <Flex flexDir="column" justifyContent="center">
                      <Text>{patient.name}</Text>
                    </Flex>
                  </Flex>
                  <Flex flexDirection="column" justifyContent="center">
                    <Text color="gray" fontSize="xs">
                      joined on {makeAlphaDate(patient.createdAt)}
                    </Text>
                  </Flex>
                </Flex>
              </Link>
            ))}
          </Flex>
        )}
      </Box>
    </Flex>
  )
}
