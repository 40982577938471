import { useState } from 'react'
import { getDoctorsService } from '../../../../services/Services'
import { useAuthState } from '../../../../auth/AuthProvider'
import { useToast } from '@chakra-ui/react'

interface UseContactTriggerReturn {
  isLoading: boolean
  error: string | null
  success: boolean
  triggerContact: () => Promise<void>
}

export const useContactTrigger = (): UseContactTriggerReturn => {
  const { uid } = useAuthState()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState(false)
  const toast = useToast()

  const triggerContact = async () => {
    try {
      setIsLoading(true)
      setError(null)
      setSuccess(false)

      await getDoctorsService().contactDoctor({ ownerId: uid })

      setSuccess(true)
      toast({
        title: 'Contact requested',
        description: 'We will reach out to you soon.',
        status: 'success'
      })
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred')
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    error,
    success,
    triggerContact
  }
}
