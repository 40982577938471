import type { VisitService } from './VisitService'
import { type FirestoreService } from '../http/FirestoreService'
import { type StatisticsService } from '../statistics/StatisticsService'
import { model } from '../../db'
import { type Patient } from '../../model/patient'

export class VisitServiceImpl implements VisitService {
  private readonly firestoreService: FirestoreService
  private readonly statisticsService: StatisticsService

  private static readonly collectionName = 'visits'
  private static readonly parentCollectionName = 'patients'

  constructor(
    firestoreService: FirestoreService,
    statisticsService: StatisticsService
  ) {
    this.firestoreService = firestoreService
    this.statisticsService = statisticsService
  }

  async createVisit(
    ownerId: string,
    patientId: string,
    data: any
  ): Promise<any> {
    const patientRef = this.firestoreService.getDocumentRef(
      VisitServiceImpl.parentCollectionName,
      patientId
    )

    if (data.isFollowUp) {
      await this.statisticsService.updateFollowUpsStatistics(ownerId)
    } else {
      await this.statisticsService.updateVisitsStatistics(ownerId)
    }

    const newVisit = await this.firestoreService.createChildDocument(
      patientRef,
      VisitServiceImpl.collectionName,
      {
        ...data,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        date: data.date ?? Date.now()
      }
    )

    return newVisit
  }

  async updateVisit(
    patientId: string,
    visitId: string,
    data: any
  ): Promise<any> {
    const patientRef = this.firestoreService.getDocumentRef(
      VisitServiceImpl.parentCollectionName,
      patientId
    )

    return await this.firestoreService.updateChildDocument(
      patientRef,
      VisitServiceImpl.collectionName,
      visitId,
      { ...data, updatedAt: Date.now() }
    )
  }

  async getVisits(patientId: string): Promise<any[]> {
    const patientRef = this.firestoreService.getDocumentRef(
      VisitServiceImpl.parentCollectionName,
      patientId
    )

    return await this.firestoreService.getChildDocuments(
      patientRef,
      VisitServiceImpl.collectionName
    )
  }

  async deleteVisit(patientId: string, visitId: string): Promise<void> {
    const patientRef = this.firestoreService.getDocumentRef(
      VisitServiceImpl.parentCollectionName,
      patientId
    )

    await this.firestoreService.deleteChildDocument(
      patientRef,
      VisitServiceImpl.collectionName,
      visitId
    )
  }

  async getAISummary(patient: Patient, visits: any): Promise<any> {
    const prompt = `
      You are a doctor assistant. You are given all visit data of a patient.
      Please summarize the visit data into a brief short summary. 
      Make sure to include the patient's name, age, gender.
      Highlight the most important information with bold text (do not overuse it), do not use headers.
      You don't need to include details from every visit, just the overall summary.
      Please be concise and to the point, do not include any medical advice, just the summary of investigations, diagnoses and treatments.
      The patient's name is ${patient.name}, birth date is ${
      patient.dateOfBirth
    }, gender is ${patient.sex}.
      The visit data is: ${JSON.stringify(visits)}
    `

    const result = await model.generateContent(prompt)
    const response = result.response
    const text = response.text()

    return text
  }
}
