/* eslint-disable no-prototype-builtins */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Grid,
  Input,
  ModalFooter,
  useToast
} from '@chakra-ui/react'
import { useAuthState } from '../../../../../../../auth/AuthProvider'
import { type Patient } from '../../../../../../../model/patient'
import { useAppDispatch } from '../../../../../../../store/hooks'
import { useState } from 'react'
import {
  addPatient,
  updateCurrentPatient
} from '../../../../../../../reducers/patientsReducer'
import { setCurrentPatient } from '../../../../../../../reducers/currentPatientReducer'
import { getPatientService } from '../../../../../../../services/Services'
import Camera, { FACING_MODES } from 'react-html5-camera-photo'
import { Form } from 'react-final-form'
import InputField from '../../../../../../../components/common/form/InputField'
import RadioField from '../../../../../../../components/common/form/RadioField'
import { GENDER_OPTIONS } from './constants'
import {
  composeValidators,
  isValidDate,
  required
} from '../../../../../../../components/common/form/validators'
import { ArrowsRightLeftIcon, CameraIcon } from '@heroicons/react/24/outline'
import TextareaField from '../../../../../../../components/common/form/TextareaField'
import { toCamelCase } from '../../../../../../../utils/schemaUtils'
import { type DoctorType } from '../../../../../../../model/doctor'
import { useNavigate } from 'react-router-dom'

interface Props {
  historySchema: DoctorType['schema']['history']
  isEditMode?: boolean
  currentPatient?: Patient
  onClose: () => void
}

export default function PatientForm({
  historySchema,
  isEditMode = false,
  currentPatient = undefined,
  onClose
}: Props) {
  const dispatch = useAppDispatch()
  const { uid } = useAuthState()
  const toast = useToast()
  const navigate = useNavigate()

  const [uploadedFiles, setUploadedFiles] = useState<any[]>()
  const [uploadedCaptures, setUploadedCaptures] = useState<any[]>([])
  const [camera, setCamera] = useState(false)
  const [cameraView, setCameraView] = useState('user')

  const handlePatientSubmit = (newValues: any, form: any) => {
    if (isEditMode) {
      const updatedValues: any = {}

      // todo extract this logic
      for (const key in currentPatient) {
        if (newValues.hasOwnProperty(key)) {
          updatedValues[key] = newValues[key] // its in old and new, means it was updated
        } else {
          updatedValues[key] = null // its in old, but not in new, means it was cleared
        }
      }

      for (const key in newValues) {
        if (!currentPatient?.hasOwnProperty(key)) {
          updatedValues[key] = newValues[key] // its in new but not old, means it is completely new
        }
      }

      getPatientService()
        .updatePatient({
          id: currentPatient?.id,
          attachments: uploadedFiles,
          captures: uploadedCaptures,
          ...updatedValues
        })
        .then((response) => {
          dispatch(updateCurrentPatient(response))
          dispatch(setCurrentPatient(response))
          toast({
            title: 'Patient updated.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
        })
    } else {
      getPatientService()
        .createPatient({
          ...newValues,
          ownerId: uid,
          attachments: uploadedFiles,
          captures: uploadedCaptures
        })
        .then((response) => {
          dispatch(addPatient(response))
          dispatch(setCurrentPatient(response))
          toast({
            title: 'Patient created.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          navigate(`/patients/${response.id}`)
          form.reset()
        })
    }
    handleClose()
  }

  const handleImageUpload = (event: any) => {
    setUploadedFiles(event.target.files)
  }

  const handleTakePhoto = (dataUri: any) => {
    setUploadedCaptures((prev: any) => [...prev, dataUri])
  }

  const toggleCamera = () => {
    setCamera((prev) => !prev)
  }

  const toggleCameraView = () => {
    if (cameraView === FACING_MODES.ENVIRONMENT) {
      setCameraView(FACING_MODES.USER)
    } else {
      setCameraView(FACING_MODES.ENVIRONMENT)
    }
  }

  const handleClose = () => {
    setCamera(false)
    setUploadedCaptures([])
    setUploadedFiles([])
    onClose()
  }

  return (
    <Form
      onSubmit={handlePatientSubmit}
      initialValues={currentPatient}
      render={({ handleSubmit, hasValidationErrors }) => (
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-between h-full"
        >
          <div className="grid grid-cols-2 gap-2">
            <InputField name="name" label="Patient name" validate={required} />
            <InputField
              name="dateOfBirth"
              label="Date of birth"
              validate={composeValidators(required, isValidDate)}
            />
            <InputField name="nationality" label="Nationality" />
            <RadioField
              name="sex"
              label="Sex"
              options={GENDER_OPTIONS}
              validate={composeValidators(required)}
            />
            <InputField name="address" label="Address" />
            <InputField name="phoneNumber" label="Phone number" />
            <Input
              type="file"
              onChange={handleImageUpload}
              multiple
              size="sm"
              className="mt-4"
            />
            <Flex gap={2}>
              <Button
                colorScheme="blue"
                size="sm"
                variant="solid"
                onClick={toggleCamera}
                leftIcon={<CameraIcon color="white" width="20px" />}
              >
                {camera ? 'Turn off camera' : 'Take photos'}
              </Button>
              <Button
                color="secondary"
                size="sm"
                leftIcon={<ArrowsRightLeftIcon className="w-5 h-5" />}
                onClick={toggleCameraView}
              >
                Toggle Camera
              </Button>
            </Flex>
            {camera && (
              <div className="col-span-2">
                <Camera
                  onTakePhoto={(dataUri) => {
                    handleTakePhoto(dataUri)
                  }}
                  idealFacingMode={cameraView as any}
                />
              </div>
            )}

            <div className="col-span-2 flex flex-row flex-wrap justify-center">
              {uploadedCaptures.map((capture: any, index: number) => (
                <img src={capture} key={index} className="w-24" />
              ))}
            </div>

            <Accordion className="col-span-2">
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      {historySchema.displayName}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>

                <AccordionPanel pb={4}>
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    {historySchema.fields.map((field: any) => (
                      <TextareaField
                        key={field.id}
                        name={`history.${toCamelCase(field.displayName)}`}
                        label={field.displayName}
                        description={field.description}
                      />
                    ))}
                  </Grid>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>
          <ModalFooter mt="12px">
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isDisabled={hasValidationErrors}
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      )}
    />
  )
}
