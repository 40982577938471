import { Flex, Select, Text } from '@chakra-ui/react'
import { Field } from 'react-final-form'

interface Props {
  name: string
  label: string
  options: Array<{ label: string; value: string }>
}

export default function SelectField({ name, label, options }: Props) {
  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <Flex flexDir="column" gap={1}>
          <Text>{label}</Text>
          <Select size="sm" {...input}>
            <option>Select an option</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          {meta.touched && meta.error && (
            <span className="text-xs text-red-500">{meta.error}</span>
          )}
        </Flex>
      )}
    />
  )
}
